import TomSelectController from "../../../../../app/javascript/controllers/tom_select_controller.js";

export default class extends TomSelectController {
  static targets = ["select", "searchTerms", "form", "termsResults"];

  connect() {
    super.connect && super.connect();
    this.searchManagersElement = this.element.closest(".search-managers");
    this.headerElement = document.querySelector("header");
    this.headerHeight = this.headerElement ? this.headerElement.offsetHeight : 0;
    window.addEventListener("scroll", this.#toggleStickyOnScroll.bind(this));
  }

  selectTargetConnected(element) {
    if (typeof super.selectTargetConnected === "function") {
      super.selectTargetConnected(element);
    }
    element.tomSelect.on("change", (event) => {
      this.formTarget.requestSubmit();
    });

    const tsWrapper = element.parentElement.querySelector(".ts-wrapper");

    const observer = new MutationObserver((mutations) => {
      for (const mutation of mutations) {
        mutation.addedNodes.forEach((node) => {
          if (node.nodeType === Node.ELEMENT_NODE && node.hasAttribute("data-ts-item")) {
            this.#cloneSelectedOption(node, element);
            element.parentElement.querySelector(".dotted_selected").classList.remove("hidden");
            this.#toggleTermsResultsVisibility();
          }
        });
      }
    });

    observer.observe(tsWrapper, {
      childList: true,
      subtree: true,
    });

    this.#selectedOptions(element);
    element.observer = observer;

    element.addEventListener("change", this.#toggleTermsResultsVisibility.bind(this));
  }

  selectTargetDisconnected(element) {
    if (typeof super.selectTargetDisconnected === "function") {
      super.selectTargetDisconnected(element);
    }
    element.observer.disconnect();
  }

  disconnect() {
    if (typeof super.disconnect === "function") {
      super.disconnect();
    }
    window.removeEventListener("scroll", this.#toggleStickyOnScroll.bind(this));
  }

  #selectedOptions(element) {
    const selectedOptions = element.parentElement.querySelector(".ts-wrapper").querySelectorAll("[data-ts-item]");
    for (const node of selectedOptions) {
      this.#cloneSelectedOption(node, element);
    }
    this.#toggleTermsResultsVisibility();
  }

  #cloneSelectedOption(node, element) {
    // duplicate the node and append it to the search terms.
    const newNode = node.cloneNode(true);
    node.classList.add("!hidden");
    newNode.querySelector("a").addEventListener("click", (event) => {
      event.preventDefault();
      element.tomSelect.removeItem(node.dataset.value);
      newNode.remove();
      this.#toggleTermsResultsVisibility();

      const selectedOptions = element.parentElement.querySelector(".ts-wrapper").querySelectorAll("[data-ts-item]");
      if (selectedOptions.length <= 0) {
        element.parentElement.querySelector(".dotted_selected").classList.add("hidden");
      }
    });
    this.searchTermsTarget.appendChild(newNode);
  }

  #toggleTermsResultsVisibility() {
    const hasSelectedItems = this.searchTermsTarget.children.length > 0;
    if (hasSelectedItems) {
      this.termsResultsTarget.classList.add("active");
    } else {
      this.termsResultsTarget.classList.remove("active");
    }
  }

  #toggleStickyOnScroll() {
    if (window.scrollY > 300) {
      this.searchManagersElement.classList.add("sticky-bar");
      this.searchManagersElement.style.top = `${this.headerHeight}px`;
    } else {
      this.searchManagersElement.classList.remove("sticky-bar");
      this.searchManagersElement.style.top = "0";
    }
  }
}
