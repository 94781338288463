import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  autoDismissDuration = 10000;

  connect() {
    alert = this.element;
    if (alert.classList.contains('alert-loading')) return;

    setTimeout(() => {
      this.dismiss();
    }, this.autoDismissDuration);
  }

  dismiss() {
    alert.classList.add("slide-out");

    setTimeout(() => {
      alert.remove();
    }, 500);
  }
}
