import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.applyColorToChart("irr", "141, 55, 186", "239, 68, 68");
    this.applyColorToChart("fmv", "141, 55, 186");
  }

  applyColorToChart(chartName, colorRGB, negativeColorRGB = null) {
    var chart = Chartkick.charts[`${chartName}_graph`];
    if (chart) {
      var chartContainer = document.getElementById(`${chartName}_chart`);
      if (!chartContainer) return

      var chartData = chart.dataSource;
      var options = chart.options.dataset;
      if (chart.dataSource.at(-1).data !== undefined) {
        chartData = chart.dataSource.at(-1).data;
        options = chart.dataSource.at(-1).dataset;
      }
      var ctx = chart.chart.ctx;
      var gradient = ctx.createLinearGradient(0, 0, 0, chartContainer.clientHeight * 0.8);
      if (this.shouldUseNegativeColor(chartData, negativeColorRGB)) {
        this.redrawChartWithColors(chart, options, gradient, negativeColorRGB, true);
      } else {
        this.redrawChartWithColors(chart, options, gradient, colorRGB, false);
      }
    }
  }

  shouldUseNegativeColor(dataSource, negativeColor) {
    return negativeColor != null && dataSource.at(-1).at(-1) < 0;
  }

  redrawChartWithColors(chart, options, gradient, color, isNegative = false) {
    var solidColor = `rgba(${color}, 0.8)`;
    gradient.addColorStop(isNegative ? 1 : 0, `rgba(${color}, 0.2)`);
    gradient.addColorStop(isNegative ? 0 : 1, `rgba(${color}, 0)`);
    options.backgroundColor = gradient;
    options.pointBorderColor = solidColor;
    options.pointBackgroundColor = solidColor;
    options.borderColor = solidColor;
    chart.redraw();
  }
}
