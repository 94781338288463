import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["dateRange", "customDate", "form", "spinner", "updateButton"];

  connect() {
    document.addEventListener("turbo:before-fetch-request", this.showSpinner.bind(this));
    document.addEventListener("turbo:before-fetch-response", this.hideSpinner.bind(this));
  }
  disconnect() {
    document.removeEventListener("turbo:before-fetch-request", this.showSpinner.bind(this));
    document.removeEventListener("turbo:before-fetch-response", this.hideSpinner.bind(this));
  }

  dateRangeChanged() {
    if (this.dateRangeTarget.value === "Custom") {
      this.customDateTarget.classList.remove("hidden");
    } else {
      this.customDateTarget.classList.add("hidden");
      this.formTarget.requestSubmit();
    }
  }

  showSpinner() {
    this.spinnerTarget.classList.remove("hidden");
    this.dateRangeTarget.disabled = true;
    this.updateButtonTarget.disabled = true;
  }

  hideSpinner() {
    this.spinnerTarget.classList.add("hidden");
    this.dateRangeTarget.disabled = false;
    this.updateButtonTarget.disabled = false;
  }
}
