import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "primaryStreet1",
    "primaryStreet2",
    "primaryCity",
    "primaryState",
    "primaryZip",
    "primaryCountry",
    "mailingStreet1",
    "mailingStreet2",
    "mailingCity",
    "mailingState",
    "mailingZip",
    "mailingCountry",
    "mailingSameAsPrimary",
    "mailingAddressContainer",
  ];

  connect() {
    this.setMailingSameAsPrimaryIfMailingAddressEmpty();
    this.updateMailingAddressIfSameAsPrimary();
    this.addEventListeners();
  }

  disconnect() {
    this.removeEventListeners();
  }

  addEventListeners() {
    for (const targetName of this.constructor.targets) {
      const target = this.getTarget(targetName);
      if (target) target.addEventListener("input", this.updateMailingAddressIfSameAsPrimary.bind(this));
    }
  }

  removeEventListeners() {
    for (const targetName of this.constructor.targets) {
      const target = this.getTarget(targetName);
      if (target) target.removeEventListener("input", this.updateMailingAddressIfSameAsPrimary.bind(this));
    }
  }

  setMailingSameAsPrimaryIfMailingAddressEmpty() {
    const mailingStreet1 = this.getTargetValue("mailingStreet1");
    const mailingStreet2 = this.getTargetValue("mailingStreet2");
    const mailingCity = this.getTargetValue("mailingCity");
    const mailingState = this.getTargetValue("mailingState");
    const mailingZip = this.getTargetValue("mailingZip");
    const mailingCountry = this.getTargetValue("mailingCountry");

    if (!mailingStreet1 && !mailingStreet2 && !mailingCity && !mailingState && !mailingZip && !mailingCountry) {
      this.mailingSameAsPrimaryTarget.checked = true;
    }
  }

  updateMailingAddressIfSameAsPrimary = () => {
    if (this.mailingSameAsPrimaryTarget.checked) {
      this.setSingleField("mailingStreet1", this.getTargetValue("primaryStreet1"));
      this.setSingleField("mailingStreet2", this.getTargetValue("primaryStreet2"));
      this.setSingleField("mailingCity", this.getTargetValue("primaryCity"));
      this.setSingleField("mailingState", this.getTargetValue("primaryState"));
      this.setSingleField("mailingZip", this.getTargetValue("primaryZip"));
      this.setSingleField("mailingCountry", this.getTargetValue("primaryCountry"));

      this.hideMailingAddressContainer();
    } else {
      this.showMailingAddressContainer();
    }
  };

  setSingleField(targetName, value) {
    const target = this.getTarget(targetName);

    if (target) target.value = value;
  }

  getTarget(targetName) {
    return this.targets.find(targetName);
  }

  getTargetValue(targetName) {
    const target = this.getTarget(targetName);

    return target ? target.value : null;
  }

  showMailingAddressContainer() {
    this.mailingAddressContainerTarget.classList.remove("!hidden");
  }

  hideMailingAddressContainer() {
    this.mailingAddressContainerTarget.classList.add("!hidden");
  }
}
