import { Controller } from "@hotwired/stimulus";
import { post } from "@rails/request.js";

export default class extends Controller {
  static targets = ["moreManagersContainer"];
  static values = { selected: Number, cachedAt: Number, loading: String, refreshUrl: String };
  connect() {
    this.checkFundsRowHeight();
    window.addEventListener("resize", this.checkFundsRowHeight.bind(this));
    document.querySelectorAll("[id^=capital_account_stats_]")[0].classList.remove("hidden");
    const selected = document.getElementById(`fund_${this.selectedValue}`);
    selected?.classList?.add("active-state");

    window.addEventListener("load", this.entitySelectorSticky.bind(this));
    if (this.loadingValue === "true") {
      // We need to add some delay to make sure that turbostream connection is established
      // to avoid race conditions, we should ideally revisit this, but for now this works
      setTimeout(() => {
        post(this.refreshUrlValue, {
          body: JSON.stringify({ cached_at: this.cachedAtValue }),
        });
      }, 3000);
    }
  }

  switchActiveFund(event) {
    const allFundSelectors = document.querySelectorAll("[id^=fund_]");
    const activeFund = document.getElementById(`fund_${event.params.id}`);
    allFundSelectors.forEach((stat) => {
      stat.classList.remove("active-state");
    });
    activeFund.classList.add("active-state");
    this.updateMoreManagers(event.params.showMoreManagers);
  }

  updateMoreManagers(show) {
    if (show) {
      this.moreManagersContainerTarget.classList.remove("invisible");
    } else {
      this.moreManagersContainerTarget.classList.add("invisible");
    }
  }

  toggleMoreFunds() {
    const fundsRow = document.querySelector(".funds-row");
    const showMoreBtn = document.querySelector(".show-more-btn");
    fundsRow.classList.toggle("show-all");

    if (fundsRow.classList.contains("show-all")) {
      const caretIcon = document.createElement("i");
      caretIcon.className = "ph-bold ph-caret-up";
      showMoreBtn.innerHTML = "";
      showMoreBtn.appendChild(caretIcon);
      showMoreBtn.appendChild(document.createTextNode(" Show Less Funds"));
    } else {
      const caretIcon = document.createElement("i");
      caretIcon.className = "ph-bold ph-caret-down";
      showMoreBtn.innerHTML = "";
      showMoreBtn.appendChild(caretIcon);
      showMoreBtn.appendChild(document.createTextNode(" Show More Funds"));
    }

    this.checkFundsRowHeight();
  }

  checkFundsRowHeight() {
    const fundsRow = document.querySelector(".funds-row");
    if (!fundsRow) return;
    const firstEntity = fundsRow.querySelector(".entity");
    if (!firstEntity) return;

    if (fundsRow.scrollHeight > firstEntity.offsetHeight) {
      document.querySelector(".show-more-container").classList.remove("hidden");
    } else {
      document.querySelector(".show-more-container").classList.add("hidden");
    }

    this.entitySelectorSticky();
  }

  entitySelectorSticky() {
    const headerHeight = document.querySelector("header").offsetHeight;
    document.querySelector(".entity-selector-wrapper").style.top = headerHeight + "px";
  }
}
