import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["tab", "panel"];

  connect() {
  }

  switch(event) {
    event.preventDefault();
    const tabName = event.target.dataset.tabName;
    this.showPanel(tabName);
  }

  showPanel(tabName) {
    this.tabTargets.forEach((tab, index) => {
      const panel = this.panelTargets[index];
      if (tab.dataset.tabName === tabName) {
        tab.classList.add("active-state");
        panel.classList.remove("hidden");
      } else {
        tab.classList.remove("active-state");
        panel.classList.add("hidden");
      }
    });
  }
}
