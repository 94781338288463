import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["button"];

  connect() {
    this.checkScreenSize();
    window.addEventListener("resize", this.checkScreenSize.bind(this));
  }

  disconnect() {
    window.removeEventListener("resize", this.checkScreenSize.bind(this));
  }

  toggleMenu() {
    this.element.classList.toggle("side-menu-opened");
    this.buttonTarget.classList.toggle("c-layout--header-mobile_menu_trigger_open");
    this.buttonTarget.classList.toggle("c-layout--header-mobile_menu_trigger_close");
  }

  checkScreenSize() {
    if (window.innerWidth >= 1360) {
      this.element.classList.remove("side-menu-opened");
    }
  }
}
