import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["body"];

  toggle() {
    this.bodyTarget.classList.toggle("!hidden");
  }

  scrollAndExpand() {
    this.bodyTarget.classList.remove("!hidden");
    this.bodyTarget.scrollIntoView({block: "center", inline: "nearest", behavior: "smooth" });
  }
}
