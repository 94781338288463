// Import and register all your controllers from the importmap under controllers/*

import { application } from "./application.js";

// Eager load all controllers defined in the import map under controllers/**/*_controller
import { registerControllers } from "stimulus-vite-helpers";
const controllers = import.meta.glob("./**/*_controller.js", { eager: true });

import { Dropdown } from "tailwindcss-stimulus-components";
application.register("dropdown", Dropdown);

registerControllers(application, controllers);
